import { connect } from 'react-redux';
import React from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Button,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import GetDays from '../../../components/Ceek/RetailsFilter/GetDays';
const UserDropdown = (props) => {
  const handleRetail = (e, retail) => {
    e.stopPropagation();
    props.selectRetail(retail);
  };
  return (
    <DropdownMenu>
      {props.retails.map((retail) => (
        <DropdownItem
          key={'r' + retail.retailId}
          tag="div"
          href="#"
          style={{ zIndex: 99999 }}
          className={`border-bottom  ${
            retail.retailId === props.selectedRetail.retailId && 'active'
          }`}
          onClick={(e) => handleRetail(e, retail)}
        >
          <div className="swiper-slide rounded swiper-shadow p-0">
            <div className="d-flex center mb-1">
              <i className="feather icon-user font-medium-4 mr-1"></i>
              <div className="swiper-text">{`${
                retail.retailIdExterno
                  ? retail.retailIdExterno
                  : retail.retailId
              } - ${retail.name}`}</div>
            </div>
            <div className="d-flex center mb-1">
              <i className="feather icon-map-pin font-medium-4 mr-1"></i>
              <div className="swiper-text">{`${retail.address}`}</div>
            </div>

            {props.planning.isPlanning ? (
              <>
                {props.planning.retailId !== retail.retailId ? (
                  <div className="text-left">
                    <Badge color="danger">
                      Estás planificando para otro punto de venta
                    </Badge>
                  </div>
                ) : (
                  <div className="text-left">
                    <Badge color="success">
                      Estás modificando la planifición{' '}
                      {props.planning.schedulId}
                    </Badge>
                  </div>
                )}
              </>
            ) : (
              <div className="text-left">
                {retail.ableToBuy ? (
                  <>
                    {retail.scheduledOrderId && props.importPlaning ? (
                      <Badge
                        color="success"
                        onClick={() =>
                          props.handleSelectEvent({
                            id: retail.scheduledOrderId,
                          })
                        }
                      >
                        Planificación #{retail.scheduledOrderId}
                      </Badge>
                    ) : (
                      <small className="success ml-0 w-100">
                        Habilitado para pedir
                      </small>
                    )}
                  </>
                ) : (
                  <>
                    {retail.enabled ? (
                      <small className="danger msjretail ml-0 w-100">
                        Días habilitados para pedir:{' '}
                        <GetDays retail={retail}></GetDays>
                      </small>
                    ) : (
                      <>
                        <small className="danger msjretail ml-0 w-100">
                          Punto de venta bloquedo:
                          {retail.blockreason}
                          <br />
                          <div className="d-flex justify-content-center">
                            <a href={props.helpUrl} target="_blank">
                              <Button
                                color="danger"
                                size="sm"
                                className="mt-05"
                              >
                                Iniciar un reclamo
                              </Button>
                            </a>
                          </div>
                        </small>
                      </>
                    )}
                  </>
                )}
              </div>
            )}

            {/* {retail.enabled === 0 && (
              <Badge color="danger" className="text-left">
                {retail.blockreason}{" "}
              </Badge>
            )} */}
          </div>
        </DropdownItem>
      ))}
    </DropdownMenu>
  );
};

const NavbarRetails = (props) => {
  const handleRetail = (retail) => {
    props.selectRetail(retail);
  };

  const retail = props.selectedRetail;

  return (
    <>
      <UncontrolledDropdown
        tag="div"
        disabled={props.disabled}
        className="dropdown-user nav-item"
        style={{ flex: 1 }}
      >
        <DropdownToggle
          disabled={props.disabled}
          tag="a"
          className="nav-link dropdown-user-link"
        >
          <div
            key={'r' + retail.retailId}
            className="swiper-slide rounded swiper-shadow "
            onClick={() => handleRetail(retail)}
          >
            <div className="d-flex center ">
              <i
                className="feather icon-map-pin font-medium-4 mr-05"
                style={{ lineHeight: 1.5 }}
              ></i>
              <div>
                <div className="swiper-text">{`${
                  retail.retailIdExterno
                    ? retail.retailIdExterno
                    : retail.retailId
                } - ${retail.name}`}</div>
                <div style={{ marginTop: '-6px' }}>
                  {props.planning.isPlanning ? (
                    <>
                      {props.planning.retailId !== retail.retailId ? (
                        <div className="text-left">
                          <Badge color="danger" size="sm">
                            Estás planificando para otro punto de venta
                          </Badge>
                        </div>
                      ) : (
                        <div className="text-left">
                          <Badge color="success" size="sm">
                            Estás modificando la planifición{' '}
                            {props.planning.schedulId}
                          </Badge>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="text-left">
                      {retail.ableToBuy ? (
                        <>
                          {retail.scheduledOrderId && props.importPlaning ? (
                            <Badge
                              color="success"
                              size="sm"
                              onClick={() =>
                                props.handleSelectEvent({
                                  id: retail.scheduledOrderId,
                                })
                              }
                            >
                              Planificación #{retail.scheduledOrderId}
                            </Badge>
                          ) : (
                            <small className="success ml-0 w-100">
                              Habilitado para pedir
                            </small>
                          )}
                        </>
                      ) : (
                        <>
                          {retail.enabled ? (
                            <small className="danger msjretail ml-0 w-100">
                              Habilitado: <GetDays retail={retail}></GetDays>
                            </small>
                          ) : (
                            <>
                              <small className="danger msjretail ml-0 w-100">
                                {`Punto de venta bloquedo`}
                              </small>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </>
  );
};

const mapStateToProps = (state) => ({
  retails: state.ecommerce.retails,
  selectedRetail: state.ecommerce.selectedRetail,
  planning: state.ecommerce.planning,
  helpUrl: state.ecommerce.helpUrl,
});

const mapDispatch = (dispatch) => ({
  selectRetail: (retail) => dispatch.ecommerce.selectRetail(retail),
});

export default connect(mapStateToProps, mapDispatch)(NavbarRetails);

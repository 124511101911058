const getMax = (jsonParams, { properties }) => {
  return {
    max:
      properties.max < jsonParams?.value ? properties.max : jsonParams?.value,
    msj:
      properties.max < jsonParams
        ? properties.msj
        : { max: `Debe ser menor que  ${jsonParams?.value}` },
  };
};
export default getMax;

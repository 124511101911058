import { store } from '../index';
import _ from 'lodash';
const ECommerceServices = (instance) => {
  const getHelpUrl = (key) => instance.get('/b2b/parameters/' + key);
  const getProductPresentationsByRetail = (retailId, source) =>
    instance.get('/b2b/product-presentations/retail/' + retailId, {
      cancelToken: source.token,
    });
  const getProductPresentationById = (
    productPresentationId,
    retailId,
    warehouseId,
  ) =>
    instance.get(
      '/b2b/product-presentations/' +
        productPresentationId +
        '/retail/' +
        retailId +
        '/warehouse/' +
        warehouseId,
    );
  const getGoalsForToday = (orderId) =>
    instance.get('/b2b/orders/' + orderId + '/weight-category-goals');
  const getBannersByLanguage = () =>
    instance.get(
      '/b2b/banners?languagesId=' + store.getState().sso.languagesId,
    );
  const getRetailsByUser = () =>
    instance.get(
      '/b2b/users/retails?languagesId=' + store.getState().sso.languagesId,
    );
  const getBalanceByFranchisee = (franchiseeId) =>
    instance.get('/b2b/franchisee-balance/' + franchiseeId);
  const getBalanceByFranchiseeDate = (franchiseeId, date) =>
    instance.get('/b2b/balance/' + franchiseeId + '/' + date);

  const getPaymentPlatformsBySupplier = (
    supplierId,
    retailId,
    purchaseTypeId,
  ) =>
    instance.get(
      '/b2b/suppliers/' +
        supplierId +
        '/' +
        retailId +
        '/' +
        purchaseTypeId +
        '/payment-platforms?languagesId=' +
        store.getState().sso.languagesId,
    );
  const postOrders = (orders) => {
    return instance.post('b2b/orders', orders);
  };
  const productOutCartAudits = (product) =>
    instance.post('b2b/product-out-cart-audits', product);
  const putPlanning = (planning) =>
    instance.put('b2b/orders/' + planning.id, planning.products);
  const getTaxes = (retailId, supplierId, productId, amount) =>
    instance.get(
      'b2b/retails/' +
        retailId +
        '/supplier/' +
        supplierId +
        '/product/' +
        productId +
        '/taxes?amount=' +
        amount,
    );
  const checkRules = (retailId, formulaInstanceId, products) =>
    instance.post(
      `/b2b/sheet-import/rules/retails/${retailId}/formula-instances/${formulaInstanceId}`,
      products,
    );
  const calculateTaxes = (retailId, products) =>
    instance.post(`/b2b/sheet-import/taxes/retails/${retailId}`, products);
  const getOrdersByRetails = (from, to) =>
    instance.get('/b2b/orders-by-retails?dateFrom=' + from + '&dateTo=' + to);
  const getFaqs = () =>
    instance.get('/b2b/faqs?languagesId=' + store.getState().sso.languagesId);
  const getScheduled = (retailId, date) =>
    instance.get('/b2b/orders/scheduled/' + retailId + '/' + date);
  const getRules = (retailId) =>
    instance.get('/b2b/retails/' + retailId + '/formulas');

  const getScheduledById = (id) => instance.get('/b2b/orders/' + id);
  const validateRules = (retailId, formulaInstanceId, payload) =>
    instance.post(
      '/b2b/retails/' +
        retailId +
        '/formula-instances/' +
        formulaInstanceId +
        '/rules',
      payload,
    );
  const getActiveSurvey = () => instance.get('/b2b/survey/active');
  const completeSurvey = (id, answer) =>
    instance.post('/b2b/survey/complete/' + id + '?answer=' + answer, {});
  const cancelOrder = (idOrder, statusId) =>
    instance.put('/b2b/orders/cancel', {
      orders: [idOrder],
      workflowStepIdActual: statusId,
    });

  const getUpdatedJob = (id) => instance.get(`/jobs/${id}`);
  const generateLogisticCode = () => instance.post('/logistic-codes', {});

  return {
    getProductPresentationsByRetail,
    getProductPresentationById,
    getBannersByLanguage,
    getRetailsByUser,
    getPaymentPlatformsBySupplier,
    postOrders,
    getTaxes,
    getOrdersByRetails,
    getBalanceByFranchisee,
    getBalanceByFranchiseeDate,
    getFaqs,
    getScheduled,
    getScheduledById,
    putPlanning,
    getUpdatedJob,
    getHelpUrl,
    getGoalsForToday,
    validateRules,
    getRules,
    getActiveSurvey,
    cancelOrder,
    completeSurvey,
    productOutCartAudits,
    checkRules,
    calculateTaxes,
    generateLogisticCode,
  };
};
export default ECommerceServices;

export const mobileStyle = {
  wrap: {
    background: 'transparent',
    fontSize: 14,
    margin: 'auto',
    marginRight: '5px',
  },
  'input.mobile': {
    color: '5f5f5f',
    padding: '0',
    border: 0,
    display: 'block',
    fontWeight: 400,
    backgroundColor: 'white',
    height: '26px',
  },
  'input:focus': {
    outline: 'none',
  },
  arrowUp: {
    borderBottomColor: '#fff',
  },
  arrowDown: {
    borderTopColor: '#fff',
  },
  plus: {
    background: 'white',
  },
  minus: {
    background: 'white',
  },
  'btnUp.mobile': {
    background: '#7367F0',
    borderRadius: '5px',
    height: '22px',
    width: '22px',
    top: '2px',
    cursor: 'pointer',
  },
  'btnDown.mobile': {
    background: '#7367F0',
    borderRadius: '5px',
    height: '22px',
    width: '22px',
    top: '2px',
    cursor: 'pointer',
  },
};

export const defaultStyle = {
  arrowUp: {
    borderBottomColor: '#fff',
  },

  arrowDown: {
    borderTopColor: '#fff',
  },
  btnUp: {
    backgroundColor: '#7367F0',
    background: '#7367F0',
    border: 'none',
    borderRadius: '2px',
  },
  btnDown: {
    backgroundColor: '#7367F0',
    background: '#7367F0',
    border: 'none',
    borderRadius: '2px',
  },
  'btn:hover': {
    background: '#7367F0',
  },
  'btn:active': {
    background: '#7367F0',
  },
  input: {
    color: '5f5f5f',
    padding: '0.7rem 0.7rem',
    border: '1px solid #D9D9D9',
    display: 'block',
    borderRadius: '5px',
    fontWeight: 400,
    backgroundColor: '#fff',
    height: 'calc(1.25em + 1.4rem + 1px)',
    width: '100%',
    fontSize: '0.96rem',
    lineHeight: '1.25',
  },
};

import axios from 'axios';
import GoalsServices from './GoalsServices';

const instance = axios.create({
  baseURL: window.config.apiBase.goals,
});

const setToken = (jwt) => {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
};

const apiGoals = {
  GoalsServices: GoalsServices(instance),
  setToken: setToken,
};
export default apiGoals;

const GoalsServices = (instance) => {
  const getGoals = (retailIdExterno) =>
    instance.get('b2b/goals/' + retailIdExterno);
  const getBalanceByFranchiseeDate = (franchiseeId, date) =>
    instance.get('/b2b/balance/' + franchiseeId + '/' + date);

  return {
    getGoals,
    getBalanceByFranchiseeDate,
  };
};
export default GoalsServices;

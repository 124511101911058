import axios from 'axios';
import GridonetServices from './GridonetServices';
import ECommerceServices from './ECommerceServices';

const instance = axios.create({
  baseURL: window.config.apiBase.ceek,
});

const setToken = (jwt) => {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
};

const apiCeek = {
  GridonetServices: GridonetServices(instance),
  ECommerceServices: ECommerceServices(instance),
  setToken: setToken,
};
export default apiCeek;

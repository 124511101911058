import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Product from '../Product';
import { Search } from 'react-feather';
import { FormGroup, Input } from 'reactstrap';

const ProductList = (props) => {
  const [value, setValue] = React.useState('');
  const { orderIndex } = props;

  const getOrder = (i: number) =>
    props.cart[i] !== undefined ? props.cart[i] : [];

  const list =
    getOrder(orderIndex).productList !== undefined
      ? getOrder(orderIndex).productList
      : [];
  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  const filter = (data) => {
    if (value.length < 3) return data;
    return data.filter(
      (row) =>
        JSON.stringify(row).toLowerCase().indexOf(value.toLowerCase()) > -1,
    );
  };

  return (
    <div className="content-right">
      <div className="email-app-area">
        <div className="email-app-list-wrapper">
          <div className="email-app-list bg-white">
            <div className="app-fixed-search">
              {/* <div
                className="d-lg-none sidebar-toggle"
                onClick={() => props.mainSidebar(true)}
              >
                <Menu size={24} />
              </div> */}
              <FormGroup className="position-relative has-icon-left m-0 d-inline-block d-lg-block">
                <Input
                  placeholder="Buscar productos en esta orden"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                  value={value}
                />
                <div className="form-control-position">
                  <Search size={15} />
                </div>
              </FormGroup>
            </div>

            <PerfectScrollbar
              className="email-user-list bg-whitesmoke"
              option={{
                wheelPropagation: true,
              }}
            >
              <ul className="users-list-wrapper media-list">
                {filter(list).map((product, productIndex) => (
                  <Product
                    orderIndex={orderIndex}
                    productIndex={productIndex}
                    key={productIndex}
                    currency={props.currency ? props.currency : ''}
                  />
                ))}
              </ul>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.ecommerce.cart,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

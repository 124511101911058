import apiCeek from '../services/CeekServices';
import apiGoals from '../services/ExternalServices';
import moment, { now } from 'moment';
import { FORMULA_INSTANCE_IDS } from '../utils/CONST';
import ApiTime from '../services/TimeServices';
import _ from 'lodash';
const isAbleToBuy = (retail, timezone) => {
  if (retail.ignoreCalendar) return true;
  const { retailCalendar } = retail;
  const isDay = retailCalendar?.find((d) => d.day === new Date().getDay());
  let canBuy = false;
  const expRegNumEntero = /GMT/;
  const split = timezone.split(expRegNumEntero); // Se corta el timezone en dos ['Etc/', '+3'] por la expresion regular de arriba.
  const nroGmtRecived = Number(split[1]); // Accedemos a la segunda posicion del array devuelto en split '+3' y lo convertimos en nro.
  const nroGmtCalculated = nroGmtRecived * -1; // Se multiplica por -1 para cambiarle el signo y se pasa a string.
  if (isDay) {
    const hoy = moment().utcOffset(nroGmtCalculated).format('HH:mm');
    const ahora = moment(hoy, 'LTS');
    const apertura = moment(isDay.timeStart, 'LTS');
    const cierre = moment(isDay.timeEnd, 'LTS');
    canBuy = ahora.isBefore(cierre) && ahora.isAfter(apertura);
  }
  return canBuy;
};
export default {
  state: {
    isClockCorrect: true,
    banners: [],
    // catalogue: { retailId: null, catalogue: [] },
    retails: [],
    selectedRetail: null,
    cart: [],
    pallets: [],
    orders: [],
    balance: [],
    goals: [],
    errors: [],
    checkoutErrors: [],
    cartUpdate: false,
    modifyingOrders: null,
    reserved: [],
    scheduled: [],
    rules: [],
    planning: {
      isPlanning: false,
      schedulId: null,
      date: null,
      retailId: null,
    },
    loadingCheckOut: false,
    loadingCart: false,
    helpUrl: null,
    loginError: null,
    timezone: null,
    preferedNewCharge: true,
    cantPorCategoryAndEconomicGroup: {},
  },
  reducers: {
    setLoginError(state, error) {
      return { ...state, loginError: error };
    },
    setCantPorCategoryAndEconomicGroup(state, data) {
      return { ...state, cantPorCategoryAndEconomicGroup: data };
    },

    clearAll(state, error) {
      return {
        isClockCorrect: true,
        banners: [],
        // catalogue: { retailId: null, catalogue: [] },
        retails: [],
        selectedRetail: null,
        cart: [],
        pallets: [],
        orders: [],
        balance: [],
        goals: [],
        errors: [],
        checkoutErrors: [],
        cartUpdate: false,
        modifyingOrders: null,
        reserved: [],
        scheduled: [],
        rules: [],
        planning: {
          isPlanning: false,
          schedulId: null,
          date: null,
          retailId: null,
        },
        loadingCheckOut: false,
        loadingCart: false,
        helpUrl: null,
        loginError: null,
        timezone: null,
        preferedNewCharge: true,
        cantPorCategoryAndEconomicGroup: {},
      };
    },
    setPreferedNewCharge(state, payload) {
      return { ...state, preferedNewCharge: payload };
    },
    setBanners(state, banners) {
      return { ...state, banners: banners };
    },
    // setCatalogue(state, { retailId, catalogue }) {
    //   return {
    //     ...state,
    //     catalogue: { retailId: retailId, catalogue: catalogue },
    //   };
    // },
    // clearCatalogue(state, payload) {
    //   return { ...state, catalogue: { retailId: null, catalogue: [] } };
    // },
    setIsClockCorrect(state, isCorrect) {
      if (window.config?.timeApi?.ignoreTimeOffset)
        return { ...state, isClockCorrect: true };
      return { ...state, isClockCorrect: isCorrect };
    },
    setPlanning(state, { planning, schedulId, date, retailId }) {
      return {
        ...state,
        planning: {
          isPlanning: planning,
          schedulId: schedulId,
          date: date,
          retailId: retailId,
        },
      };
    },
    clearPlanning(state) {
      return {
        ...state,
        planning: {
          isPlanning: false,
          schedulId: null,
          date: null,
          retailId: null,
        },
      };
    },
    setScheduled(state, scheduled) {
      return { ...state, scheduled: scheduled };
    },
    setHelpUrl(state, url) {
      return { ...state, helpUrl: url };
    },
    setLoadingCheckOut(state, loadingCheckOut) {
      return { ...state, loadingCheckOut: loadingCheckOut };
    },
    setLoadingCart(state, loadingCart) {
      return { ...state, loadingCart: loadingCart };
    },
    setRetails(state, retails, timezone) {
      return {
        ...state,
        retails: retails.map((r, i) => {
          return {
            ...r,
            ableToBuy: r.enabled ? isAbleToBuy(r, timezone) : false,
          };
        }),
        timezone: timezone,
      };
    },
    setSelectedRetail(state, r) {
      return {
        ...state,
        selectedRetail: {
          ...r,
          ableToBuy: r.enabled ? isAbleToBuy(r, state.timezone) : false, // isAbleToBuy(retail.retailCalendar),
        },
      };
    },
    updateRetails(state) {
      return {
        ...state,
        retails: state.retails.map((r) => {
          return {
            ...r,
            ableToBuy: r.enabled ? isAbleToBuy(r, state.timezone) : false, // isAbleToBuy(r)
          };
        }),
      };
    },
    setBalance(state, balance) {
      return { ...state, balance: balance };
    },
    setRules(state, rules) {
      return { ...state, rules: rules };
      //rules };
      //formulas  (mock)};
    },
    setGoals(state, goals) {
      return { ...state, goals: goals };
    },
    clearBalance(state) {
      return { ...state, balance: [] };
    },
    clearGoals(state) {
      return { ...state, goals: [] };
    },
    setCart(state, newCart) {
      return { ...state, cartUpdate: !state.cartUpdate, cart: [...newCart] };
    },
    setModifyingOrders(state, modifyingOrders) {
      return { ...state, modifyingOrders: modifyingOrders };
    },
    clearCart(state) {
      return { ...state, cartUpdate: !state.cartUpdate, cart: [] };
    },
    clearOrders(state) {
      return { ...state, orders: [] };
    },
    removeFromCart(state, order, productPresentationId) {
      let newCart = state.cart;
      const ord = newCart.findIndex((el) => el.id === order.id);
      newCart[ord].productList = newCart[ord].productList.filter(
        (el) => el.productPresentationId !== productPresentationId,
      );
      if (newCart[ord].productList.length === 0) {
        newCart = newCart.filter((el) => el.id !== order.id);
      }
      return { ...state, cartUpdate: !state.cartUpdate, cart: [...newCart] };
    },
    setOrders(state, orders) {
      return { ...state, orders: orders };
    },
    // removeOrderFromCart(state, orderId) {
    //   return {
    //     ...state,
    //     cart: [...state.cart.filter((o) => o.id !== orderId)],
    //   };
    // },

    setReserved(state, products) {
      return { ...state, reserved: products };
    },
    ecommercelogout(state) {
      return {
        banners: [],
        retails: [],
        selectedRetail: null,
        cart: [],
        orders: [],
        balance: [],
        cartUpdate: false,
      };
    },
    calculatePallets(state) {
      const cart = state.cart;
      let calculatedPallets = {};
      cart.forEach((order) => {
        let totalCubicaje = 0;
        order.productList.forEach((product) => {
          if (product.isCombo) {
            totalCubicaje += product.products.reduce(
              (vol, prod) =>
                vol + prod.volume * prod.quantityInCombo * product.cant,
              0,
            );
          } else {
            if (product.volume) totalCubicaje += product.volume * product.cant;
          }
        });
        if (totalCubicaje > 0)
          calculatedPallets[order.id] = {
            cantPallets: Math.ceil(totalCubicaje / 100),
            percLastPallet:
              totalCubicaje % 100 === 0 ? 100 : Math.ceil(totalCubicaje % 100),
          };
        else
          calculatedPallets[order.id] = {
            cantPallets: 0,
            percLastPallet: 0,
          };
      });
      return { ...state, pallets: calculatedPallets };
    },
    clearPallets(state) {
      return { ...state, pallets: {} };
    },

    setErrors(state, errors) {
      return { ...state, errors: errors };
    },
    setCheckoutErrors(state, errors) {
      return { ...state, checkoutErrors: errors };
    },
    cancelarModificacion(state) {
      return { ...state, modifyingOrders: null, reserved: [] };
    },
  },

  effects: (dispatch) => ({
    async addToCartMultiple(payload, state) {
      const { itIsFromUpdateCart } = payload;
      this.setLoadingCart(true);
      if (payload.originalOrders) {
        this.setModifyingOrders(payload.originalOrders);

        this.setReserved(
          payload.products.reduce((acc, p) => {
            return {
              ...acc,
              ['reserved' +
              p.productPresentationId +
              '-' +
              p.warehouseId +
              '-' +
              p.selectedPurchaseTypeId]: p.cant,
            };
          }, {}),
        );
      }
      const validarReglas =
        !payload.isPlanning ||
        (payload.isPlanning && window.config.evaluateAddRulesOnPlanning);
      let newCart = state.ecommerce.cart;
      if (payload.clearAllCart) {
        newCart = [];
      } else if (payload.clearRetailCart) {
        newCart = newCart.filter((o) => o.retailId !== payload.retailId);
      }
      const products = payload.products;
      // this.setErrors([]); //limpio errores
      let err = [];
      let notApproved = [];
      const productsToValidateAndCalcTaxes = products.reduce(
        (acc, prod) => {
          const purchaseTypeIdAux = payload.purchase
            ? payload.purchase.purchaseId
            : prod.selectedPurchaseTypeId;
          const purchaseFIND = prod.isCombo
            ? {
                distributorSupplierId: prod.distributorSupplierId,
                noStock: 0,

                presentationId: getPresentationId(prod, purchaseTypeIdAux),
                price: getComboPrice(prod, purchaseTypeIdAux),
                productPresentationId: prod.productPresentationId,
                purchaseTypeId: purchaseTypeIdAux,
                stock: getStock(prod, purchaseTypeIdAux),
                supplierId: 2,
                warehouseId: null,
              }
            : prod.purchaseType.find(
                // si no busco el tipo de compra elegido (adentro tiene stock )
                (p) => purchaseTypeIdAux === p.purchaseTypeId,
              );

          const rule = {
            quantity: prod.cant,
            productPresentationId: prod.productPresentationId,
            categoryId: prod.categoryId,
            brandsId: prod.brandsId,
            purchaseTypeId: purchaseTypeIdAux,
            productsId: prod.productsId,
            distributorSupplierId: prod.distributorSupplierId,
            purchaseTypePresentationId: purchaseFIND.presentationId,
            supplierId: prod.supplierId,
            warehouseId: prod.warehouseId,
            sku: prod.productSKU,
            stock: purchaseFIND.stock,
            noStock: purchaseFIND.noStock,
          };

          const taxes = prod.isCombo
            ? prod.products?.map((p) => {
                return {
                  supplierId: p.supplierId,
                  productsId: p.productsId,
                  comboId: prod.productsId,
                  purchaseTypeId: purchaseTypeIdAux,
                  amount: p.purchaseType.find(
                    (purchaseType) =>
                      purchaseType.purchaseTypeId === purchaseTypeIdAux,
                  ).price,
                };
              })
            : [
                {
                  supplierId: prod.supplierId,
                  productsId: prod.productsId,
                  comboId: prod.productsId,
                  purchaseTypeId: purchaseTypeIdAux,
                  amount: prod.purchaseType.find(
                    (purchaseType) =>
                      purchaseType.purchaseTypeId === purchaseTypeIdAux,
                  ).price,
                },
              ];

          return {
            forRules: [...acc.forRules, rule],
            forTaxes: [...acc.forTaxes, ...taxes],
          };
        },
        { forRules: [], forTaxes: [] },
      );
      let allTaxes = [];
      try {
        if (validarReglas) {
          const response = await apiCeek.ECommerceServices.checkRules(
            payload.retailId,
            FORMULA_INSTANCE_IDS.ON_ADD_TO_CART,
            productsToValidateAndCalcTaxes.forRules,
          ).catch((er) => {
            alert(er);
          });
          notApproved = response.data ?? [];
        }
        const response = await apiCeek.ECommerceServices.calculateTaxes(
          payload.retailId,
          productsToValidateAndCalcTaxes.forTaxes,
        ).catch((er) => {
          alert(er);
        });
        allTaxes = response.data ?? [];
      } catch (e) {
        this.setLoadingCart(false);
        console.log(e);
        alert('algo grave fallo');
        return;
      }
      for await (const productos of products) {
        const isInNotApproved = notApproved.find(
          (notAppro) => notAppro.productsId === productos.productsId,
        );

        if (isInNotApproved) {
          err = [
            ...err,
            {
              productPresentationName: productos.productTitle,
              productPresentationId: productos.productPresentationId,
              errorMessage: isInNotApproved.errorMessage,
            },
          ];
          continue;
        }
        const purchaseTypeIdAux = payload.purchase
          ? payload.purchase.purchaseId
          : productos.selectedPurchaseTypeId;
        productos['originalQuantity'] = payload.originalOrders
          ? productos.cant
          : state.ecommerce?.reserved?.[
              'reserved' +
                productos.productPresentationId +
                '-' +
                productos.warehouseId +
                '-' +
                purchaseTypeIdAux
            ]
          ? state.ecommerce.reserved[
              'reserved' +
                productos.productPresentationId +
                '-' +
                productos.warehouseId +
                '-' +
                purchaseTypeIdAux
            ]
          : 0;

        const purchaseFIND = productos.isCombo
          ? {
              distributorSupplierId: productos.distributorSupplierId,
              noStock: 0,

              presentationId: getPresentationId(productos, purchaseTypeIdAux),
              price: getComboPrice(productos, purchaseTypeIdAux),
              productPresentationId: productos.productPresentationId,
              purchaseTypeId: purchaseTypeIdAux,
              stock: getStock(productos, purchaseTypeIdAux),
              supplierId: 2,
              warehouseId: null,
            }
          : productos.purchaseType.find(
              // si no busco el tipo de compra elegido (adentro tiene stock )
              (p) => purchaseTypeIdAux === p.purchaseTypeId,
            );

        let order = {
          retailId: payload.retailId,
          id: `${payload.retailId}|${purchaseTypeIdAux}|${
            productos.distributorSupplierId
              ? productos.distributorSupplierId
              : productos.supplierId
          }`, //|${productos.warehouseId}`,
          economicGroupId: payload.economicGroupId,
          purchaseType: purchaseTypeIdAux,
          purchaseTypeName: purchaseFIND?.purchaseTypeName,
          supplier: productos.supplierName,
          supplierId: productos.supplierId,
          warehouseId: productos.warehouseId,
          warehouseName: productos.warehouseName,
          distributorSupplierId: productos.distributorSupplierId
            ? productos.distributorSupplierId
            : null,
          cartId: null, // intToHex(parseInt(cartId)),
          distributorSupplier: productos.distributorSupplierName,
          productList: [],
          schedulId: payload.schedul ? payload.schedul.id : null,
        };
        const productTaxes = productos.isCombo
          ? allTaxes.filter((tax) =>
              productos.products.some(
                (p) =>
                  p.productsId === tax.productsId &&
                  tax.comboId === productos.productsId &&
                  tax.purchaseTypeId === purchaseTypeIdAux,
              ),
            )
          : allTaxes.filter(
              (tax) =>
                tax.productsId === productos.productsId &&
                tax.comboId === productos.productsId &&
                tax.purchaseTypeId === purchaseTypeIdAux,
            );
        newCart = await Addproduct(
          newCart,
          order,
          productos,
          payload,
          purchaseTypeIdAux,
          purchaseFIND,
          productTaxes,
          itIsFromUpdateCart,
        );

        this.setCart(newCart);
        this.calculatePallets();
        this.setLoadingCart(false);
      }
      this.setErrors(err);
      this.setLoadingCart(false);
      return true;
    },
    async updateClock() {
      if (!window.config?.timeApi?.ignoreTimeOffset) {
        ApiTime.TimeService.getTime()
          .then((res) => {
            const localDate = moment().utc(false);
            const dataResponse = _.get(res, window.config.timeApi.responseKey);
            const realDate = moment(dataResponse).utc(false);
            this.setIsClockCorrect(
              realDate
                ? Math.abs(realDate.diff(localDate, 'minutes')) <
                    window.config.timeApi.aceptedOffset &&
                    Math.abs(realDate.diff(localDate, 'days')) < 1 &&
                    Math.abs(realDate.diff(localDate, 'hours')) < 1
                : true,
            );
          })
          .catch((e) => {
            this.setIsClockCorrect(true);
          });
      }
    },
    async selectRetail(retail, state) {
      this.updateClock();
      if (
        !state.selectedRetail ||
        retail.franchiseeId !== state.selectedRetail.franchiseeId
      ) {
        this.clearBalance();
        this.clearGoals();

        apiCeek.ECommerceServices.getBalanceByFranchisee(retail.franchiseeId)
          .then((res) => {
            this.setBalance(res.data);
          })
          .catch((e) => console.log(e.message));
        apiCeek.ECommerceServices.getRules(retail.retailId)
          .then((res) => this.setRules(res.data))
          .catch((e) => console.log(e.message));
        apiGoals.GoalsServices.getGoals(retail.retailIdExterno)
          .then((res) => {
            this.setGoals(res.data);
          })
          .catch((e) => console.log(e.message));
      }
      this.updateRetails();
      this.setSelectedRetail(retail);
    },
    async handleCart(
      { order, productPresentationId, cant, isPlanning },
      state,
    ) {
      this.setLoadingCart(true);
      const validarReglas =
        !isPlanning || (isPlanning && window.config.evaluateAddRulesOnPlanning);
      let newCart = [...state.ecommerce.cart];
      const ord = newCart.findIndex((el) => el.id === order.id);
      const prod = newCart[ord].productList.findIndex(
        (el) => el.productPresentationId === productPresentationId,
      );
      //VALIDAR REGLAS
      if (validarReglas) {
        apiCeek.ECommerceServices.validateRules(
          //valido reglas
          newCart[ord].retailId,
          FORMULA_INSTANCE_IDS.ON_ADD_TO_CART,
          {
            quantity: cant,
            productPresentationId:
              newCart[ord].productList[prod].productPresentationId,
            categoryId: newCart[ord].productList[prod].categoryId,
            brandsId: newCart[ord].productList[prod].brandsId,
            purchaseTypeId: newCart[ord].purchaseType,
            productsId: newCart[ord].productList[prod].productsId,
            distributorSupplierId: newCart[ord].distributorSupplierId,
            purchaseTypePresentationId:
              newCart[ord].productList[prod].purchaseType.presentationId,
            supplierId: newCart[ord].supplierId,
            warehouseId: newCart[ord].productList[prod].warehouseId,
            sku: newCart[ord].productList[prod].productSKU,
            stock: newCart[ord].productList[prod].purchaseType.stock,
            noStock: newCart[ord].productList[prod].purchaseType.noStock,
          },
        )
          .then(async (response) => {
            const isntAble = response.data.some((r) => r.valid === false);
            if (isntAble) {
              //si una regla no paso
              const err = response.data
                .filter((e) => !e.valid)
                .map((r) => {
                  if (!r.valid) {
                    return {
                      productPresentationName:
                        newCart[ord].productList[prod].productTitle,
                      productPresentationId:
                        newCart[ord].productList[prod].productPresentationId,
                      errorMessage: r.errorMessage,
                    };
                  }
                });
              this.setErrors(err); //seteo los errores
              this.setLoadingCart(false);
            } else {
              // .then((response) => {

              // }).catch((e)=>{

              // });
              // //FIN VALIDAR REGLAS

              if (cant === 0)
                newCart[ord].productList = newCart[ord].productList.filter(
                  (el) => el.productPresentationId !== productPresentationId,
                );
              else newCart[ord].productList[prod].cant = cant;

              if (newCart[ord].productList.length === 0) {
                newCart = newCart.filter((el) => el.id !== order.id);
              }

              this.setLoadingCart(false);
              this.setCart(newCart);
              this.calculatePallets();
            }
          })
          .catch((e) => {
            //error al llamar a las reglas
            this.setLoadingCart(false);
            console.log(e);
          });
      } else {
        if (cant === 0)
          newCart[ord].productList = newCart[ord].productList.filter(
            (el) => el.productPresentationId !== productPresentationId,
          );
        else newCart[ord].productList[prod].cant = cant;

        if (newCart[ord].productList.length === 0) {
          newCart = newCart.filter((el) => el.id !== order.id);
        }

        this.setLoadingCart(false);

        this.setCart(newCart);
        this.calculatePallets();
      }
    },
    removeOrderFromCart(orderId, state) {
      const newCart = state.ecommerce.cart.filter((o) => o.id !== orderId);
      this.setCart(newCart);
      this.calculatePallets();
    },
  }),
};

const Addproduct = async (
  newCart,
  order,
  productos,
  payload,
  purchaseTypeIdAux,
  purchaseFIND,
  taxes,
  itIsFromUpdateCart,
) => {
  const ord = newCart.findIndex((el) => el.id === order.id);
  if (ord !== -1) {
    //hay orden
    const prod = newCart[ord].productList.findIndex(
      (el) =>
        el.productPresentationId === productos.productPresentationId &&
        el.warehouseId === productos.warehouseId,
    );

    if (prod !== -1) {
      //hay producto
      if (itIsFromUpdateCart) {
        if (productos.cant > 0) {
          newCart[ord].productList[prod].cant = productos.cant;
        } else {
          newCart[ord].productList.splice(prod, 1);
          if (newCart[ord].productList.length <= 0) {
            newCart.splice(ord, 1);
          }
        }
      } else {
        if (productos.cant > 0) {
          newCart[ord].productList[prod].cant += productos.cant;
        }
      }
    } else {
      if (productos.cant > 0) {
        //es  producto nuevo
        let taxesCount = [];

        if (productos.isCombo) {
          const productToAdd = {
            ...productos,
            products: productos.products.map((p) => {
              return {
                ...p,
                taxes: taxes.filter(
                  (t) =>
                    // t.supplierId === p.supplierId &&
                    t.productsId === p.productsId,
                ),
                purchaseType: p.purchaseType.find(
                  (pro) => pro.purchaseTypeId === purchaseTypeIdAux,
                ),
                economicGroupId: payload.economicGroupId,
                economicGroupIdExterno: payload.economicGroupIdExterno,
                cartId: newCart[ord].cartId,
                cartName: newCart[ord].id,
              };
            }),
            purchaseType: purchaseFIND,
            //  productos.purchaseType.find(
            //   (p) => p.purchaseTypeId === purchaseTypeIdAux
            // ),
            cartId: newCart[ord].cartId,
            cartName: newCart[ord].id,
            economicGroupId: payload.economicGroupId,
            economicGroupIdExterno: payload.economicGroupIdExterno,
            taxes: [
              taxes.reduce(
                (allTax, thisTax) => {
                  const pr = productos.products.find(
                    (p) => p.productsId === thisTax.productsId,
                  );
                  return {
                    ...allTax,
                    taxAmount:
                      allTax.taxAmount + thisTax.taxAmount * pr.quantityInCombo,
                  };
                },
                { taxName: 'Imp', taxAmount: 0 },
              ),
            ],
          };

          order.productList.push({
            ...productToAdd,
            cant: productos.cant,
          });
          //se pushea
          newCart[ord].productList.push(productToAdd);
        } else {
          const productToAdd = {
            ...productos,
            purchaseType: productos.purchaseType.find(
              (p) => p.purchaseTypeId === purchaseTypeIdAux,
            ),
            cartId: newCart[ord].cartId,
            cartName: newCart[ord].id,
            economicGroupId: payload.economicGroupId,
            economicGroupIdExterno: payload.economicGroupIdExterno,
            taxes: taxes,
          };

          newCart[ord].productList.push(productToAdd);
        }
      }
    }
  } else {
    // es orden nueva
    if (productos.cant > 0) {
      // let taxes = [];

      //Endpoint que genera y devuelve el código logístico.
      let cartId;
      await apiCeek.ECommerceServices.generateLogisticCode()
        .then((response) => {
          cartId = response.data.nroCompleted;
        })
        .catch((er) => {
          // alert(er);
          const a = new Date();
          const daystringshortest =
            a.getHours() +
            '' +
            a.getMinutes() +
            '' +
            a.getSeconds() +
            '' +
            a.getMilliseconds() +
            '' +
            Math.trunc(Math.random() * 10);
          cartId = `${daystringshortest}`.replace(/-/g, '');
        });

      order.cartId = cartId;

      if (productos.isCombo) {
        const productToAdd = {
          ...productos,
          products: productos.products.map((p) => {
            return {
              ...p,
              taxes: taxes.filter(
                (t) =>
                  // t.supplierId === p.supplierId &&
                  t.productsId === p.productsId,
              ),
              purchaseType: p.purchaseType.find(
                (pro) => pro.purchaseTypeId === purchaseTypeIdAux,
              ),
              economicGroupId: payload.economicGroupId,
              economicGroupIdExterno: payload.economicGroupIdExterno,
              cartId: cartId,
              cartName: order.id,
            };
          }),
          purchaseType: purchaseFIND,
          cartId: cartId,
          cartName: order.id,
          economicGroupId: payload.economicGroupId,
          economicGroupIdExterno: payload.economicGroupIdExterno,
          taxes: [
            taxes.reduce(
              (allTax, thisTax) => {
                const pr = productos.products.find(
                  (p) => p.productsId === thisTax.productsId,
                );
                return {
                  ...allTax,
                  taxAmount:
                    allTax.taxAmount + thisTax.taxAmount * pr.quantityInCombo,
                };
              },
              { taxName: 'Imp', taxAmount: 0 },
            ),
          ],
        };

        order.productList.push({
          ...productToAdd,
          cant: productos.cant,
        });
        newCart.push(order);
      } else {
        const productToAdd = {
          ...productos,
          purchaseType: productos.purchaseType.find(
            (p) => p.purchaseTypeId === purchaseTypeIdAux,
          ),
          cartId: cartId,
          cartName: order.id,
          economicGroupId: payload.economicGroupId,
          economicGroupIdExterno: payload.economicGroupIdExterno,
          taxes: taxes,
        };

        order.productList.push({
          ...productToAdd,
          cant: productos.cant,
        });
        newCart.push(order);
      }
    }
  }

  return newCart;
};

const getStock = (combo, purhcaseTypeID) => {
  return Math.min(
    ...combo.products.map((p) => {
      const ptype =
        p.purchaseType.find((p) => p.purchaseTypeId === purhcaseTypeID) || {};
      return ptype.noStock
        ? Infinity
        : Math.floor(ptype.stock / p.quantityInCombo) > 0
        ? Math.floor(ptype.stock / p.quantityInCombo)
        : 0;
    }),
  );
};
const getPresentationId = (combo, purhcaseTypeID) => {
  return combo.products[0].purchaseType.find(
    (p) => p.purchaseTypeId === purhcaseTypeID,
  )?.presentationId; //helacor usa siempre la misma, presentationId==22, presentationName:"bultos"
};
const getComboPrice = (combo, purhcaseTypeID) => {
  return combo.products.reduce((acc, p) => {
    const ptype = p.purchaseType.find(
      (p) => p.purchaseTypeId === purhcaseTypeID,
    );
    return acc + ptype?.price * p.quantityInCombo;
  }, 0);
};

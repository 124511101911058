import React, { useRef, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

const Days = {
  '0': 'Domingo',
  '1': 'Lunes',
  '2': 'Martes',
  '3': 'Miercoles',
  '4': 'Jueves',
  '5': 'Viernes',
  '6': 'Sabados',
};
const GetDays = (props) => {
  const days = props.retail.retailCalendar;
  const today = moment().day();
  const ableToday = days.find((d) => d.day === today);

  return (
    <>
      {ableToday ? (
        <>
          {Days[ableToday.day]} {ableToday.timeStart}hs.-{ableToday.timeEnd}hs.
        </>
      ) : (
        <>
          {days.map((p, i) => (
            <span key={i}>
              <span
                className="hoverday"
                id={'r' + props.retail.retailId + '-day-' + i}
              >
                {Days[p.day]}
                {i < days.length - 2
                  ? ', '
                  : i === days.length - 2
                  ? ' y '
                  : ''}{' '}
              </span>
              <UncontrolledTooltip
                placement="bottom"
                target={'r' + props.retail.retailId + '-day-' + i}
              >
                {p.timeStart}hs.-{p.timeEnd}hs.
              </UncontrolledTooltip>
            </span>
          ))}
        </>
      )}
    </>
  );
};
export default GetDays;

const showMessageStockByObjectives = (
  jsonParams,
  { properties, product, cart },
) => {
  if (product.objective) {
    const allProductsInCart = cart.reduce((productLists, cart) => {
      if (cart.retailId !== product.retailId) {
        return [...productLists, ...cart.productList];
      } else {
        return [...productLists];
      }
    }, []);
    const thisProductInCart = allProductsInCart.filter((p) => {
      return (
        p.productsId === product.productsId &&
        p.objective?.economicGroupId === product.objective?.economicGroupId
      );
    });
    const cantOfThisProductInCart = thisProductInCart.reduce(
      (cant, pr) => cant + pr.cant,
      0,
    );
    return {
      max:
        product.objective?.available || product.objective?.available === 0
          ? product.objective?.reserved
            ? Math.max(
                product.objective?.available -
                  product.objective?.reserved -
                  cantOfThisProductInCart,
                0,
              )
            : product.objective?.available - cantOfThisProductInCart
          : Infinity,
      msj: {
        objectives: `Objetivo: ${
          product.objective?.objective_quantity
        }, Resto mes anterior: ${
          product.objective?.rest_previous_month
            ? product.objective?.rest_previous_month
            : 0
        }, Cumplimiento: ${
          product.objective?.compliance_quantity
            ? product.objective?.compliance_quantity
            : 0
        }, Cupo: ${
          product.objective?.quota != null
            ? product.objective?.quota
            : 'Sin cupo'
        }, Consumido: ${Math.max(
          product.objective?.reserved + cantOfThisProductInCart,
          0,
        )}, Disponible: ${Math.max(
          product.objective?.available -
            product.objective?.reserved -
            cantOfThisProductInCart,
          0,
        )}
      `,
      },
    };
    // properties.msj = { objectives: "Debe cumplir con la regla de objetivos" };
  } else {
    return properties;
  }
};

export default showMessageStockByObjectives;

import operations from './OperationsPlus';

export const getProps = (product, formulas, cart?) => {
  let properties = {
    max: Infinity,
    min: 0,
    msj: {},
  };

  const rulev2 = formulas.filter(
    (f) =>
      (f.productsId === product.productsId || !f.productsId) &&
      (f.categoryId === product.categoryId || !f.categoryId) &&
      (f.purchaseTypeId === product.purchaseTypeId || !f.purchaseTypeId),
  );
  for (const rule of rulev2) {
    if (operations[rule.formulaName]) {
      const result = operations[rule.formulaName](
        JSON.parse(rule.formulaJsonParameters),
        { properties, product, cart },
      );
      properties = {
        ...properties,
        ...result,
        msj: { ...properties.msj, ...result.msj },
      };
    } else {
      properties = {
        ...properties,
        msj: {
          ...properties.msj,
          err: 'no encontramos la formula por su nombre',
        },
      };
    }
  }

  return properties;
};

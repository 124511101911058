import getMax from './getMax';
import getMin from './getMin';
import getStep from './getStep';
import showMessageStockByObjectives from './stockByObjectives';
const operations = {
  max: getMax,
  min: getMin,
  mult: getStep,
  showMessageStockByObjectives: showMessageStockByObjectives,
};
export default operations;

const getMin = (jsonParams, { properties }) => {
  return {
    min:
      properties.min > jsonParams?.value ? properties.min : jsonParams?.value,
    msj:
      properties.min > jsonParams
        ? properties.msj
        : { min: `Debe ser mayor que  ${jsonParams?.value}` },
  };
};
export default getMin;

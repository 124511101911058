import React, { useEffect } from 'react';
import {
  Media,
  Button,
  UncontrolledTooltip,
  Badge,
  Collapse,
} from 'reactstrap';
import { RefreshCw, Trash, Info, ChevronDown, ChevronUp } from 'react-feather';
import { connect } from 'react-redux';
import NumberInput from '../../Form/NumberInputs/NumberInput';
import Currency from '../../Currency';
import { getProps } from '../../Rules/RulesOnPlus';
import noImg from '../../../../assets/img/logo/noimg.png';
const Product = (props) => {
  const product = props.cart[props.orderIndex].productList[props.productIndex];
  const productoReservado =
    props.reserved?.[
      'reserved' +
        product.productPresentationId +
        '-' +
        product.warehouseId +
        '-' +
        product.purchaseType.purchaseTypeId
    ] ?? 0;
  const [inputValue, setInputValue] = React.useState(product.cant);
  const [properties, setProperties] = React.useState({
    min: 0,
    max:
      product.purchaseType.noStock || props.planning.isPlanning
        ? Infinity
        : product.purchaseType.stock + productoReservado,
    step: 1,
    msj: {},
  });
  const [minimo, setMinimo] = React.useState(0);
  const [maximo, setMaximo] = React.useState(Infinity);
  const [step, setStep] = React.useState(1);
  const [collapse, setCollapse] = React.useState(false);
  useEffect(() => {
    const proper = getProps(
      {
        retailId: props.cart[props.orderIndex].retailId,
        categoryId: product.categoryId,
        purchaseTypeId: product.purchaseType.purchaseTypeId,
        productsId: product.productsId,
        objective: product.objective,
      },
      props.rules,
      props.cart,
    );
    setProperties((prev) => {
      return { ...prev, ...proper };
    });
  }, []);
  useEffect(() => {
    setMinimo(
      properties.min % properties.step === 0
        ? properties.min
        : Math.ceil(properties.min / properties.step) * properties.step,
    );
    setMaximo(
      product.purchaseType.noStock || props.planning.isPlanning
        ? properties.max % properties.step === 0
          ? properties.max
          : Math.floor(properties.max / properties.step) * properties.step
        : product.purchaseType.stock + productoReservado < properties.max
        ? (product.purchaseType.stock + productoReservado) % properties.step ===
          0
          ? product.purchaseType.stock + productoReservado
          : Math.floor(
              (product.purchaseType.stock + productoReservado) /
                properties.step,
            ) * properties.step
        : properties.max % properties.step === 0
        ? properties.max
        : Math.floor(properties.max / properties.step) * properties.step,
    );
    setStep(properties.step);
  }, [properties]);
  useEffect(() => {
    setInputValue(product.cant);
  }, [props.cart, props.errors]);

  const handleChange = (cant) => {
    //evaluateRules (product, val)
    if (cant >= minimo && cant <= maximo && cant % step === 0) {
      setInputValue(cant);
    } else {
      let cantAux = cant;

      if (cant % step !== 0 && cant > inputValue) {
        cantAux =
          Math.trunc(cant / step) * step
            ? (Math.trunc(cant / step) + 1) * step
            : step;
      }
      if (cant % step !== 0 && cant < inputValue) {
        cantAux =
          Math.trunc(cant / step) * step
            ? Math.trunc(cant / step) * step
            : step;
      }
      if (cantAux < minimo) {
        cantAux = minimo;
      }
      if (cantAux > maximo) {
        cantAux = maximo;
      }

      setInputValue(cantAux);
    }

    //setInputValue(val);
  };
  const toggleCollapse = () => {
    setCollapse((prev) => !prev);
  };
  // const getDataError = () => {
  //   const max = props?.groupedByEconomicGroup?.[product.economicGroupId]?.find(
  //     (e) => e.categoria === product.categoryId,
  //   )?.monto_permitido;
  //   if (!max) return '';
  //   return (
  //     <span className="text-danger">
  //       {' Maximo permitido de la categoria: ' + max}
  //     </span>
  //   );
  // };
  return (
    <>
      <Media
        tag="li"
        key={product.productPresentationId}
        className={'mail-unread pb-0 border-bottom bg-white'}
        onClick={() => {
          // this.handleEmailDetails("open", mail)
        }}
      >
        <Media className="pr-50 d-none d-md-block" tag="div" left>
          <div className="avatar">
            <Media object src={product.imgSmall ? product.imgSmall : noImg} />
          </div>
        </Media>
        <Media body>
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div>
              <h5 className="text-bold-600 mb-25 ">{product.productTitle}</h5>
              <span className="text-truncate d-none d-md-block">
                {product.productPresentationName}
              </span>
              <span className="text-truncate d-none d-md-block">
                {product.categoryName}
                {/* {getDataError()} */}
              </span>
              {/* <small> {product.cartId}</small> */}
            </div>
            <div className="d-flex">
              {Object.keys(properties.msj).length > 0 && (
                <div>
                  <Info
                    id={
                      'ruleMsjOnCart' +
                      product.productPresentationId +
                      '' +
                      product.purchaseType.purchaseTypeId
                    }
                    size={14}
                    className="info mt-1 mr-1"
                  />

                  <UncontrolledTooltip
                    placement="bottom"
                    target={
                      'ruleMsjOnCart' +
                      product.productPresentationId +
                      '' +
                      product.purchaseType.purchaseTypeId
                    }
                  >
                    <>
                      Reglas de pedido:
                      {Object.keys(properties.msj).map((m, k) => (
                        <div className={'danger'} key={k}>
                          {properties.msj[m]}
                        </div>
                      ))}
                    </>
                  </UncontrolledTooltip>
                </div>
              )}
              <div className="w-100 text-center">
                <NumberInput
                  min={minimo}
                  max={maximo}
                  step={step}
                  value={inputValue}
                  mobile
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <div className="d-flex">
                  <Button
                    className="mt-02"
                    color="flat-primary"
                    size="sm"
                    onClick={() => {
                      props.handleCart(
                        props.cart[props.orderIndex],
                        product.productPresentationId,
                        inputValue,
                        props.planning.isPlanning,
                      );
                      props.calculatePallets();
                    }}
                  >
                    {' '}
                    <RefreshCw size={14} className="mr-05 mb-02"></RefreshCw>
                    Actualizar
                  </Button>
                  <Button
                    className="mt-02"
                    color="flat-primary"
                    size="sm"
                    onClick={() => {
                      props.removeFromCart(
                        props.cart[props.orderIndex],
                        product.productPresentationId,
                      );
                      props.calculatePallets();
                    }}
                  >
                    <Trash size={14} className="mr-05 mb-02"></Trash>
                    Eliminar
                  </Button>
                </div>
              </div>
              <div
                className="d-block w-100 text-center"
                id={
                  'price' +
                  product.productPresentationId +
                  '-' +
                  product.selectedPurchaseTypeId
                }
              >
                <Currency
                  value={(
                    product.cant *
                    (product.purchaseType.price +
                      product.taxes.reduce(
                        (acc, tax) => acc + tax.taxAmount,
                        0,
                      ))
                  ).toFixed(2)}
                  currency={props.currency ? props.currency : ''}
                />
              </div>
              {product.cant > 0 && (
                <UncontrolledTooltip
                  placement="bottom"
                  target={
                    'price' +
                    product.productPresentationId +
                    '-' +
                    product.selectedPurchaseTypeId
                  }
                >
                  ${(product.cant * product.purchaseType.price).toFixed(2)} +
                  imp : $
                  {(
                    product.taxes.reduce((acc, tax) => acc + tax.taxAmount, 0) *
                    product.cant.toFixed(2)
                  ).toFixed(2)}
                </UncontrolledTooltip>
              )}
            </div>
            {product.isCombo ? (
              <div style={{ position: 'absolute', bottom: 0, left: '48%' }}>
                <Button
                  color="primary"
                  style={{
                    borderRadius: '5px 5px 0 0px',
                    fontSize: '6px',
                    padding: '2px 22px',
                  }}
                  onClick={toggleCollapse}
                >
                  {collapse ? (
                    <ChevronUp
                      width={16}
                      height={16}
                      className={'fontsize6px'}
                    />
                  ) : (
                    <ChevronDown
                      width={16}
                      height={16}
                      className={'fontsize6px'}
                    />
                  )}
                </Button>
              </div>
            ) : null}
          </div>
          {product.isCombo ? (
            <Collapse isOpen={collapse} style={{ paddingBottom: '18px' }}>
              {product.products.map((p, k) => (
                <Media
                  tag="div"
                  key={k + 'combo' + p.productPresentationId}
                  className={'mail-unread pb-0 border-bottom bg-white'}
                  onClick={() => {
                    // this.handleEmailDetails("open", mail)
                  }}
                >
                  <Media className="pr-50 d-none d-md-block" tag="div" left>
                    <div className="avatar">
                      <Media object src={p.imgSmall ? p.imgSmall : noImg} />
                    </div>
                  </Media>

                  <Media body style={{ overflow: 'visible' }}>
                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                      <div>
                        <h5 className="text-bold-600 mb-25 ">
                          {p.productTitle}
                        </h5>
                        <span className="text-truncate d-none d-md-block">
                          {p.productPresentationName}
                        </span>
                        {/* <small> {p.cartId}</small> */}
                      </div>
                      <div
                        className="d-flex"
                        style={{
                          width: '30%',
                        }}
                      >
                        <div className="w-100 text-center">
                          <p className={'text-bold'}>
                            {product.cant} x {p.quantityInCombo}u{' '}
                          </p>
                        </div>
                        <div
                          className="d-block w-100 text-center"
                          id={
                            'p' +
                            p.productPresentationId +
                            'price' +
                            '-' +
                            product.selectedPurchaseTypeId
                          }
                        >
                          <Currency
                            value={
                              product.cant *
                                p.purchaseType.price *
                                p.quantityInCombo +
                              p.taxes.reduce(
                                (acc, tax) =>
                                  acc + tax.taxAmount * p.quantityInCombo,
                                0,
                              ) *
                                product.cant
                            }
                            currency={props.currency ? props.currency : ''}
                          />
                        </div>
                        {product.cant > 0 && (
                          <UncontrolledTooltip
                            placement="bottom"
                            target={
                              'p' +
                              p.productPresentationId +
                              'price' +
                              '-' +
                              product.selectedPurchaseTypeId
                            }
                          >
                            $
                            {(
                              product.cant *
                              p.purchaseType.price *
                              p.quantityInCombo
                            ).toFixed(2)}{' '}
                            + imp : $
                            {(
                              p.taxes.reduce(
                                (acc, tax) =>
                                  acc + tax.taxAmount * p.quantityInCombo,
                                0,
                              ) * product.cant
                            ).toFixed(2)}
                          </UncontrolledTooltip>
                        )}
                      </div>
                    </div>{' '}
                  </Media>
                </Media>
              ))}
            </Collapse>
          ) : (
            <></>
          )}
        </Media>
      </Media>
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.ecommerce.cart,
  errors: state.ecommerce.errors,
  rules: state.ecommerce.rules,
  planning: state.ecommerce.planning,
  reserved: state.ecommerce.reserved,
  modifyingOrders: state.ecommerce.modifyingOrders,
  groupedByEconomicGroup: state.ecommerce.cantPorCategoryAndEconomicGroup,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (order, productPresentationId) =>
    dispatch.ecommerce.removeFromCart(order, productPresentationId),
  handleCart: (order, productPresentationId, cant, isPlanning) =>
    dispatch.ecommerce.handleCart({
      order,
      productPresentationId,
      cant,
      isPlanning,
    }),
  calculatePallets: () => dispatch.ecommerce.calculatePallets(),
});
export default connect(mapStateToProps, mapDispatchToProps)(Product);

import React from 'react';
import { X, ShoppingCart } from 'react-feather';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ContextLayout } from '../../../utility/context/Layout';
import '../../../assets/scss/components/customizer.scss';
import Cart from '../../Ceek/Cart/Cart';
import { FormattedMessage } from 'react-intl';
class Customizer extends React.Component {
  render() {
    return (
      <ContextLayout.Consumer>
        {(context) => {
          return (
            <div
              className={classnames('customizer d-none d-md-block', {
                open: this.props.customizerState === true,
              })}
            >
              <div
                className="customizer-toggle"
                onClick={() =>
                  this.props.handleCustomizer(!this.props.customizerState)
                }
              >
                <ShoppingCart size={15} />
              </div>
              <div className="header d-flex justify-content-between px-2 pt-2">
                <div className="title">
                  <h4 className="text-uppercase mb-0">
                    <ShoppingCart size={20} className="mr-2 mb-2 mt-1" />
                    <FormattedMessage
                      id="cart.title"
                      defaultMessage="Carrito"
                    />
                  </h4>
                </div>
                <div
                  className="close-icon cursor-pointer"
                  onClick={() => this.props.handleCustomizer(false)}
                >
                  <X size={24} />
                </div>
              </div>
              <hr />
              <PerfectScrollbar
                options={{
                  wheelPropagation: false,
                }}
                className="customizer-content p-2"
              >
                <Cart />
              </PerfectScrollbar>
            </div>
          );
        }}
      </ContextLayout.Consumer>
    );
  }
}

export default Customizer;
